import * as _events2 from "@algolia/events";
var _events = "default" in _events2 ? _events2.default : _events2;
import _inherits from "../functions/inherits";
var exports = {};
var EventEmitter = _events;
var inherits = _inherits;

/**
 * A DerivedHelper is a way to create sub requests to
 * Algolia from a main helper.
 * @class
 * @classdesc The DerivedHelper provides an event based interface for search callbacks:
 *  - search: when a search is triggered using the `search()` method.
 *  - result: when the response is retrieved from Algolia and is processed.
 *    This event contains a {@link SearchResults} object and the
 *    {@link SearchParameters} corresponding to this answer.
 */
function DerivedHelper(mainHelper, fn) {
  this.main = mainHelper;
  this.fn = fn;
  this.lastResults = null;
}
inherits(DerivedHelper, EventEmitter);

/**
 * Detach this helper from the main helper
 * @return {undefined}
 * @throws Error if the derived helper is already detached
 */
DerivedHelper.prototype.detach = function () {
  this.removeAllListeners();
  this.main.detachDerivedHelper(this);
};
DerivedHelper.prototype.getModifiedState = function (parameters) {
  return this.fn(parameters);
};
exports = DerivedHelper;
export default exports;